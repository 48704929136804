import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfileDto } from '@as/api';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  private userFullName = new BehaviorSubject<string | undefined>(undefined);

  constructor() {}

  public getUserProfile(): string | null {
    return localStorage.getItem(this.userProfile());
  }

  public getJwt(): string | null {
    return localStorage.getItem(this.jwtKey());
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshKey());
  }

  public setJwt(token: string): void {
    localStorage.setItem(this.jwtKey(), token);
  }

  public setRefreshToken(token: string): void {
    localStorage.setItem(this.refreshKey(), token);
  }

  public setUserData(userProfile: UserProfileDto): void {
    localStorage.setItem(this.userProfile(), JSON.stringify(userProfile));
    this.userFullName.next(userProfile.fullName);
  }

  public clearTokens() {
    localStorage.removeItem(this.jwtKey());
    localStorage.removeItem(this.refreshKey());
  }

  private jwtKey(): string {
    return `agrosell_access_token`;
  }

  private refreshKey(): string {
    return `agrosell_refresh_token`;
  }

  private userProfile(): string {
    return `agrosell_user_profile`;
  }
}
